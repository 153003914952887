import { emptySplitApi as api } from "./emptyApi";
export const addTagTypes = [
  "getRoles",
  "getRole",
  "updateRole",
  "deleteRole",
  "createRole",
  "getServicesByRole",
  "getEmployees",
  "getEmployee",
  "deleteEmployee",
  "getTherapists",
  "getServicesByEmployee",
  "createEmployee",
  "updateEmployeeDetails",
  "updateEmployeeServices",
  "getEmployeeNotes",
  "createEmployeeNote",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getRoles: build.query<GetRolesApiResponse, GetRolesApiArg>({
        query: (queryArg) => ({
          url: `/profile/roles`,
          params: {
            sortDirection: queryArg.sortDirection,
            keyword: queryArg.keyword,
            departmentId: queryArg.departmentId,
            sortBy: queryArg.sortBy,
            status: queryArg.status,
          },
        }),
        providesTags: ["getRoles"],
      }),
      getRole: build.query<GetRoleApiResponse, GetRoleApiArg>({
        query: (queryArg) => ({ url: `/profile/role/${queryArg.id}` }),
        providesTags: ["getRole"],
      }),
      updateRole: build.mutation<UpdateRoleApiResponse, UpdateRoleApiArg>({
        query: (queryArg) => ({
          url: `/profile/role/${queryArg.id}`,
          method: "PATCH",
          data: queryArg.patchRequest,
        }),
        invalidatesTags: ["updateRole"],
      }),
      deleteRole: build.mutation<DeleteRoleApiResponse, DeleteRoleApiArg>({
        query: (queryArg) => ({
          url: `/profile/role/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["deleteRole"],
      }),
      createRole: build.mutation<CreateRoleApiResponse, CreateRoleApiArg>({
        query: (queryArg) => ({
          url: `/profile/role`,
          method: "POST",
          data: queryArg.createRoleRequest,
        }),
        invalidatesTags: ["createRole"],
      }),
      getServicesByRole: build.query<
        GetServicesByRoleApiResponse,
        GetServicesByRoleApiArg
      >({
        query: (queryArg) => ({
          url: `/profile/role/services/${queryArg.roleId}`,
        }),
        providesTags: ["getServicesByRole"],
      }),
      getEmployees: build.query<GetEmployeesApiResponse, GetEmployeesApiArg>({
        query: (queryArg) => ({
          url: `/profile/employees`,
          params: {
            sortDirection: queryArg.sortDirection,
            keyword: queryArg.keyword,
            departmentId: queryArg.departmentId,
            roleId: queryArg.roleId,
            page: queryArg.page,
            pageSize: queryArg.pageSize,
            sortBy: queryArg.sortBy,
            status: queryArg.status,
          },
        }),
        providesTags: ["getEmployees"],
      }),
      getEmployee: build.query<GetEmployeeApiResponse, GetEmployeeApiArg>({
        query: (queryArg) => ({ url: `/profile/employee/${queryArg.id}` }),
        providesTags: ["getEmployee"],
      }),
      deleteEmployee: build.mutation<
        DeleteEmployeeApiResponse,
        DeleteEmployeeApiArg
      >({
        query: (queryArg) => ({
          url: `/profile/employee/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["deleteEmployee"],
      }),
      getTherapists: build.query<GetTherapistsApiResponse, GetTherapistsApiArg>(
        {
          query: () => ({ url: `/profile/therapists` }),
          providesTags: ["getTherapists"],
        },
      ),
      getServicesByEmployee: build.query<
        GetServicesByEmployeeApiResponse,
        GetServicesByEmployeeApiArg
      >({
        query: (queryArg) => ({
          url: `/profile/employee/services/${queryArg.employeeId}`,
        }),
        providesTags: ["getServicesByEmployee"],
      }),
      createEmployee: build.mutation<
        CreateEmployeeApiResponse,
        CreateEmployeeApiArg
      >({
        query: (queryArg) => ({
          url: `/profile/employee`,
          method: "POST",
          data: queryArg.createEmployeeRequest,
        }),
        invalidatesTags: ["createEmployee"],
      }),
      updateEmployeeDetails: build.mutation<
        UpdateEmployeeDetailsApiResponse,
        UpdateEmployeeDetailsApiArg
      >({
        query: (queryArg) => ({
          url: `/profile/employee/detail/${queryArg.id}`,
          method: "PATCH",
          data: queryArg.patchRequest,
        }),
        invalidatesTags: ["updateEmployeeDetails"],
      }),
      updateEmployeeServices: build.mutation<
        UpdateEmployeeServicesApiResponse,
        UpdateEmployeeServicesApiArg
      >({
        query: (queryArg) => ({
          url: `/profile/employee/services/${queryArg.id}`,
          method: "PUT",
          data: queryArg.employeeServicePutRequest,
        }),
        invalidatesTags: ["updateEmployeeServices"],
      }),
      getEmployeeNotes: build.query<
        GetEmployeeNotesApiResponse,
        GetEmployeeNotesApiArg
      >({
        query: (queryArg) => ({ url: `/profile/notes/${queryArg.profileId}` }),
        providesTags: ["getEmployeeNotes"],
      }),
      createEmployeeNote: build.mutation<
        CreateEmployeeNoteApiResponse,
        CreateEmployeeNoteApiArg
      >({
        query: (queryArg) => ({
          url: `/profile/note`,
          method: "POST",
          data: queryArg.noteRequest,
        }),
        invalidatesTags: ["createEmployeeNote"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as edgeProfileApi };
export type GetRolesApiResponse = /** status 200  */ RolesResponse;
export type GetRolesApiArg = {
  /** asc or desc */
  sortDirection?: string;
  keyword?: string;
  /** Department Id */
  departmentId?: string;
  sortBy?: string;
  status?: number;
};
export type GetRoleApiResponse = /** status 200  */ SingleRoleResponse;
export type GetRoleApiArg = {
  id: string;
};
export type UpdateRoleApiResponse = /** status 200  */ SingleRoleResponse;
export type UpdateRoleApiArg = {
  id: string;
  patchRequest: PatchRequest;
};
export type DeleteRoleApiResponse = /** status 200  */ VoidResponse;
export type DeleteRoleApiArg = {
  id: string;
};
export type CreateRoleApiResponse = /** status 200  */ SingleRoleResponse;
export type CreateRoleApiArg = {
  createRoleRequest: CreateRoleRequest;
};
export type GetServicesByRoleApiResponse =
  /** status 200  */ ServicesByRoleResponse;
export type GetServicesByRoleApiArg = {
  roleId: string;
};
export type GetEmployeesApiResponse = /** status 200  */ EmployeesResponse;
export type GetEmployeesApiArg = {
  /** asc or desc */
  sortDirection?: string;
  keyword?: string;
  departmentId?: string;
  roleId?: string;
  page?: number;
  pageSize?: number;
  sortBy?: string;
  status?: number;
};
export type GetEmployeeApiResponse = /** status 200  */ SingleEmployeeResponse;
export type GetEmployeeApiArg = {
  id: string;
};
export type DeleteEmployeeApiResponse = /** status 200  */ VoidResponse;
export type DeleteEmployeeApiArg = {
  id: string;
};
export type GetTherapistsApiResponse = /** status 200  */ TherapistsResponse;
export type GetTherapistsApiArg = void;
export type GetServicesByEmployeeApiResponse =
  /** status 200  */ ServicesByEmployeeResponse;
export type GetServicesByEmployeeApiArg = {
  employeeId: string;
};
export type CreateEmployeeApiResponse =
  /** status 200  */ SingleEmployeeResponse;
export type CreateEmployeeApiArg = {
  createEmployeeRequest: CreateEmployeeRequest;
};
export type UpdateEmployeeDetailsApiResponse =
  /** status 200  */ SingleEmployeeResponse;
export type UpdateEmployeeDetailsApiArg = {
  id: string;
  patchRequest: PatchRequest;
};
export type UpdateEmployeeServicesApiResponse =
  /** status 200  */ ServicesByEmployeeResponse;
export type UpdateEmployeeServicesApiArg = {
  id: string;
  employeeServicePutRequest: EmployeeServicePutRequest;
};
export type GetEmployeeNotesApiResponse =
  /** status 200  */ EmployeeNotesResponse;
export type GetEmployeeNotesApiArg = {
  profileId: string;
};
export type CreateEmployeeNoteApiResponse =
  /** status 200  */ EmployeeNotesResponse;
export type CreateEmployeeNoteApiArg = {
  noteRequest: NoteRequest;
};
export type RoleMeta = {
  isEmailRequired: boolean;
  isTherapistRequired: boolean;
  isRetailCommissionRequired: boolean;
  isServiceCommissionRequired: boolean;
  productIds: string[];
};
export type RoleDepartmentDto = {
  id?: string;
  name?: string;
};
export type RoleDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  parentId?: string;
  departmentId?: string;
  type: number;
  sequence: number;
  name: string;
  tags?: string;
  description?: string;
  status: number;
  meta?: RoleMeta;
  Department: RoleDepartmentDto;
};
export type RolesResponse = {
  code?: number;
  messages?: string[];
  result?: RoleDto[];
};
export type CategoryName = {
  name: string;
};
export type RoleProductDto = {
  id?: string;
  name?: string;
  status?: number;
  categoryId?: string;
  Category?: CategoryName;
};
export type SingleRoleDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  parentId?: string;
  departmentId?: string;
  type: number;
  sequence: number;
  name: string;
  tags?: string;
  description?: string;
  status: number;
  meta?: RoleMeta;
  ProductServices?: RoleProductDto[];
  Department?: RoleDepartmentDto;
};
export type SingleRoleResponse = {
  code?: number;
  messages?: string[];
  result?: SingleRoleDto;
};
export type PatchOperation =
  | "add"
  | "replace"
  | "test"
  | "remove"
  | "move"
  | "copy";
export type Patch = {
  op: PatchOperation;
  /** Path */
  path: string;
  /** Value */
  value?: object;
  /** From */
  from?: string;
};
export type PatchRequest = {
  jsonPatch: Patch[];
};
export type VoidResponse = {
  code?: number;
  messages?: string[];
};
export type RoleMetaRequest = {
  isEmailRequired: boolean;
  isTherapistRequired: boolean;
  isRetailCommissionRequired: boolean;
  productIds: string[];
};
export type CreateRoleRequest = {
  parentId?: string;
  departmentId?: string;
  name: string;
  description?: string;
  meta: RoleMetaRequest;
  /** "espa","kbs" but use backslash in front of double quotation */
  tags?: string;
};
export type ServicesByRoleResponse = {
  code?: number;
  messages?: string[];
  result?: RoleProductDto[];
};
export type EmployeeScheduleMeta = {
  dayNameType?: number;
  workStart?: number;
  workEnd?: number;
  lunchStart?: number;
  lunchEnd?: number;
};
export type EmployeeMeta = {
  employeeSchedule: EmployeeScheduleMeta[];
};
export type IdentityDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  profileId: string;
  username?: string;
  hash?: string;
  tag?: string;
  verified: boolean;
  lastLoggedAt?: string;
  failedAttempts?: number;
  lastFailedAttemptAt?: string;
  tokenIssuedAt?: string;
  status: number;
};
export type EmployeeProfileDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  gender?: string;
  dateOfBirth?: string;
  email?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  country?: string;
  fullName: string;
  Identity?: IdentityDto;
};
export type EmployeeDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  profileId: string;
  departmentId?: string;
  roleId?: string;
  phoneNumber?: string;
  employeeNumber?: string;
  employeeCardNumber?: string;
  isCommissionOnRetailSales: boolean;
  isCommissionOnServices: boolean;
  isServiceDefault: boolean;
  isCommissionOnRetailSalesDefault: boolean;
  isCommissionOnServicesDefault: boolean;
  tags?: string;
  meta?: EmployeeMeta;
  validFrom?: string;
  validUntil?: string;
  status: number;
  Profile?: EmployeeProfileDto;
  Role?: RoleDto;
};
export type EmployeesResponse = {
  code?: number;
  messages?: string[];
  result?: EmployeeDto[];
  pageSize?: number;
  totalRecords?: number;
  totalPages?: number;
};
export type EmployeeProductDto = {
  id: string;
  categoryId?: string;
  name: string;
  status: number;
  duration?: number;
  Category?: CategoryName;
};
export type EmployeeSchedule = {
  employeeId: string;
  dayNameType: number;
  workStart?: number;
  workEnd?: number;
  lunchStart?: number;
  lunchEnd?: number;
};
export type SingleEmployeeDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  profileId: string;
  departmentId?: string;
  roleId?: string;
  phoneNumber?: string;
  employeeNumber?: string;
  employeeCardNumber?: string;
  isCommissionOnRetailSales: boolean;
  isCommissionOnServices: boolean;
  isServiceDefault: boolean;
  isCommissionOnRetailSalesDefault: boolean;
  isCommissionOnServicesDefault: boolean;
  tags?: string;
  meta?: EmployeeMeta;
  validFrom?: string;
  validUntil?: string;
  status: number;
  Profile?: EmployeeProfileDto;
  Role?: RoleDto;
  ProductServices?: EmployeeProductDto[];
  EmployeeSchedules?: EmployeeSchedule[];
};
export type SingleEmployeeResponse = {
  code?: number;
  messages?: string[];
  result?: SingleEmployeeDto;
};
export type TherapistDto = {
  id: string;
  type: 1 | 2 | 4 | 8 | 16;
  name: string;
  gender?: string;
  children?: TherapistDto[];
  parentId?: string;
};
export type TherapistsResponse = {
  code?: number;
  messages?: string[];
  result?: TherapistDto[];
};
export type ServicesByEmployeeResponse = {
  code?: number;
  messages?: string[];
  result?: EmployeeProductDto[];
};
export type CreateNoteDto = {
  message: string;
};
export type CreateEmployeeRequest = {
  firstName: string;
  lastName: string;
  middleName?: string;
  gender?: string;
  dateOfBirth?: string;
  email?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  country: string;
  roleId: string;
  phoneNumber?: string;
  employeeNumber?: string;
  employeeCardNumber?: string;
  validFrom?: string;
  tags?: string;
  note?: CreateNoteDto;
};
export type EmployeeServicePutRequest = {
  /** ["PD123456789012","PD123456789012"] */
  productIds: string[];
  isServiceDefault: boolean;
};
export type NoteDto = {
  id?: string;
  relationId?: string;
  message?: string;
  level?: number;
  createdAt?: string;
  createdByName?: string;
};
export type EmployeeNotesResponse = {
  code?: number;
  messages?: string[];
  result?: NoteDto[];
};
export type NoteRequest = {
  relationId?: string;
  /** Guest 1 or Employee 2 (default is Employee 2) */
  level?: 1 | 2;
  message: string;
  /** only when creating & editing notes in cart */
  cartItemId?: string;
  /** customerId */
  customerId?: string;
  addNoteToAll?: boolean;
  orderId?: string;
  /** customer name */
  customerName?: string;
  /** needed when adding notes to all */
  calendarId?: string;
};
export const {
  useGetRolesQuery,
  useGetRoleQuery,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
  useCreateRoleMutation,
  useGetServicesByRoleQuery,
  useGetEmployeesQuery,
  useGetEmployeeQuery,
  useDeleteEmployeeMutation,
  useGetTherapistsQuery,
  useGetServicesByEmployeeQuery,
  useCreateEmployeeMutation,
  useUpdateEmployeeDetailsMutation,
  useUpdateEmployeeServicesMutation,
  useGetEmployeeNotesQuery,
  useCreateEmployeeNoteMutation,
} = injectedRtkApi;
